import React, { useState, useEffect } from 'react'
import '@/assets/css/userstudyhome.css'
import Navbar from 'react-bootstrap/Navbar'
import {
  RiSurveyFill,
  RiExternalLinkLine,
  RiPlayCircleFill,
} from 'react-icons/ri'

import { useElapsedTime } from 'use-elapsed-time'

import axios from 'axios'
import { Link, useParams } from 'react-router-dom'

const UserStudyHome = () => {
  const { participantId } = useParams()
  sessionStorage.setItem('id', participantId || '')
  // console.log(participantId)

  const [videoIdWithAi, setVideoIdWithAi] = useState('')
  const [videoIdWithoutAi, setVideoIdWithoutAi] = useState('')
  const [userIdWithAi, setuserIdWithAi] = useState('')
  const [userIdWithoutAi, setuserIdWithoutAi] = useState('')
  const [randomOrder, setRandomOrder] = useState(false)

  const [isPlaying, setIsPlaying] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { elapsedTime } = useElapsedTime({ isPlaying })

  const fetchParticipantData = () => {
    axios
      .get(
        `${process.env.REACT_APP_YDX_BACKEND_URL}/api/create-participant-links/get-participant/${participantId}`,
      )
      .then((res) => {
        setVideoIdWithAi(res.data.youtube_video_id_with_AI)
        setVideoIdWithoutAi(res.data.youtube_video_id_without_AI)
        setuserIdWithAi(res.data.user_id_with_AI)
        setuserIdWithoutAi(res.data.user_id_without_AI)
      })
      .catch((err) => {
        console.error(err.response.data)
      })
  }

  function charIsLetter(char: any) {
    if (typeof char !== 'string') {
      return false
    }
    return /^[a-zA-Z]+$/.test(char)
  }

  useEffect(() => {
    fetchParticipantData()
    document.addEventListener('keyup', () => {
      setIsPlaying((prevIsPlaying) => !prevIsPlaying)
    })
    setRandomOrder(charIsLetter(participantId?.charAt(0)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantId])

  return (
    <div className="ydx-body ydx-html">
      <Navbar
        bg="dark"
        variant="dark"
        className="m-auto justify-content-center"
      >
        {/* <Container> */}
        <Navbar.Brand href="#home">
          <h6 className="tutorial-text text-center font-weight-bolder">
            {' '}
            Video Description User Study
          </h6>
          {/* <div className="app">
          <h1>use-elapsed-time demo</h1>
          <p>Press any key to play/pause time</p>
          <div style={{ fontSize: 56 }}>{elapsedTime.toFixed(2)}</div>
        </div> */}
          {/* <Timer/> */}
          {/* <div>
          <img 
          src='@/assets/images/pause_white.png'
          ></img> 
        </div> */}
          {/* <img
          id="btn-timer"
          src="@/assets/images/pause_white.png"
          style="height: 20px; width: 20px; color: white; margin-right: 10px"
        />
        <b
          ><span style="color: white">Time Elapsed:&nbsp;</span>
          <span style="color: white" id="txt-timeElaspsed">00:00</span></b
        > */}
        </Navbar.Brand>

        {/* </Container> */}
      </Navbar>
      <div className="container home-container">
        <div className=" text-white">
          <h6 className="user-study-text text-center font-weight-bolder">
            Note: Please watch the tutorial first. Please ensure that you
            attempt the video descriptions below in sequential order only.
          </h6>
          <h6 className="user-study-text text-center font-weight-bolder">
            For example, complete Video 1 Before Video 2
          </h6>
        </div>
        <hr className="ydx-hr" />
        <div>
          <div className="mx-auto my-auto text-bars align-items-center border rounded">
            <h6 className="tutorial-text text-center font-weight-bolder">
              <a
                href={`https://www.youtube.com/playlist?list=PLNJrbI_nyy9uzywoJfyDRoeKA1SaIEFJ7`}
                className="ydx-link"
              >
                {/* <img src={`http://img.youtube.com/vi/24Pmmo9wKik/0.jpg`} width="70" height="70"></img> */}
                <RiPlayCircleFill />
                &nbsp;Audio Description Basics for beginners
              </a>
            </h6>
          </div>
          <div className="mx-auto my-auto text-bars align-items-center border rounded">
            <h6 className="tutorial-text text-center font-weight-bolder">
              <a
                href={`https://screencast-o-matic.com/watch/c3lD3eVvBzE`}
                className="ydx-link"
              >
                <RiExternalLinkLine />
                &nbsp; Tutorial Video
              </a>
            </h6>
          </div>
        </div>
        <hr className="ydx-hr" />
        <div>
          <div className="mx-auto my-auto text-bars align-items-center border rounded">
            <h6 className="tutorial-text text-center font-weight-bolder">
              {randomOrder ? (
                <a href={`/videopage/${videoIdWithoutAi}`} className="ydx-link">
                  {/* <img src={`http://img.youtube.com/vi/${videoIdWithoutAi}/0.jpg`} width="70" height="70"></img> */}
                  <RiPlayCircleFill />
                  &nbsp; Play Video 1
                </a>
              ) : (
                <a href={`/videopage/${videoIdWithAi}`} className="ydx-link">
                  {/* <img src={`http://img.youtube.com/vi/${videoIdWithAi}/0.jpg`} width="70" height="70"></img> */}
                  <RiPlayCircleFill />
                  &nbsp; Play Video 1
                </a>
              )}
            </h6>
          </div>
          <div className="mx-auto my-auto text-bars align-items-center border rounded">
            <h6 className="tutorial-text text-center font-weight-bolder">
              {randomOrder ? (
                <Link
                  to={`/${videoIdWithoutAi}/${userIdWithoutAi}`}
                  className="ydx-link"
                >
                  {/* <img src={`http://img.youtube.com/vi/${videoIdWithAi}/0.jpg`} width="70" height="70"></img> */}
                  <RiExternalLinkLine />
                  &nbsp; FreeStyle Interface
                </Link>
              ) : (
                <Link
                  to={`/${videoIdWithAi}/${userIdWithAi}`}
                  className="ydx-link"
                >
                  {/* <img src={`http://img.youtube.com/vi/${videoIdWithAi}/0.jpg`} width="70" height="70"></img> */}
                  <RiExternalLinkLine />
                  &nbsp; AI Prompted Interface
                </Link>
              )}
            </h6>
          </div>
          <div className="mx-auto my-auto text-bars align-items-center border rounded">
            <h6 className="tutorial-text text-center font-weight-bolder">
              {randomOrder ? (
                <a
                  href={`https://docs.google.com/forms/d/e/1FAIpQLSfKgSpEspPFszXUrgwiTxWK6Qk9J9dF8EBWnqUBn8-zVu--0A/viewform?usp=pp_url&entry.851854037=${participantId}`}
                  className="ydx-link"
                >
                  {/* <img src="@/assets/images/survey.png" width="70" height="70"></img> */}
                  <RiSurveyFill />
                  &nbsp; User Survey for Video 1
                </a>
              ) : (
                <a
                  href={`https://docs.google.com/forms/d/e/1FAIpQLScN-w1k6pS3pdgEKVoYcWLhbwikAg2vbPqBDD7A4umTStoQuA/viewform?usp=pp_url&entry.221372424=${participantId}`}
                  className="ydx-link"
                >
                  {/* <img src="@/assets/images/survey.png" width="70" height="70"></img> */}
                  <RiSurveyFill />
                  &nbsp; User Survey for Video 1
                </a>
              )}
            </h6>
          </div>
          <div className="mx-auto my-auto text-bars align-items-center border rounded">
            <h6 className="tutorial-text text-center font-weight-bolder">
              {randomOrder ? (
                <a
                  href={`https://sfsu.co1.qualtrics.com/jfe/form/SV_cTR8f4CwWTomvdA?participantID=${participantId}`}
                  className="ydx-link"
                >
                  {' '}
                  <RiSurveyFill />
                  &nbsp;NASA TLX Survey for Video 1
                </a>
              ) : (
                <a
                  href={`https://sfsu.co1.qualtrics.com/jfe/form/SV_eXOXVIPvO95SlGS?participantID=${participantId}`}
                  className="ydx-link"
                >
                  {' '}
                  <RiSurveyFill />
                  &nbsp;NASA TLX Survey for Video 1
                </a>
              )}
            </h6>
          </div>
        </div>
        <hr className="ydx-hr" />
        <div>
          <div className="mx-auto my-auto text-bars align-items-center border rounded">
            <h6 className="tutorial-text text-center font-weight-bolder">
              {randomOrder ? (
                <Link to={`/videopage/${videoIdWithAi}`} className="ydx-link">
                  {/* <img src={`http://img.youtube.com/vi/${videoIdWithAi}/0.jpg`} width="70" height="70"></img> */}
                  <RiPlayCircleFill />
                  &nbsp; Play Video 2
                </Link>
              ) : (
                <Link
                  to={`/videopage/${videoIdWithoutAi}`}
                  className="ydx-link"
                >
                  {/* <img src={`http://img.youtube.com/vi/${videoIdWithoutAi}/0.jpg`} width="70" height="70"></img> */}
                  <RiPlayCircleFill />
                  &nbsp; Play Video 2
                </Link>
              )}
            </h6>
          </div>
          <div className="mx-auto my-auto text-bars align-items-center border rounded">
            <h6 className="tutorial-text text-center font-weight-bolder">
              {randomOrder ? (
                <Link
                  to={`/${videoIdWithAi}/${userIdWithAi}`}
                  className="ydx-link"
                >
                  <RiExternalLinkLine />
                  &nbsp;AI Prompted Interface
                </Link>
              ) : (
                <Link
                  to={`/${videoIdWithoutAi}/${userIdWithoutAi}`}
                  className="ydx-link"
                >
                  <RiExternalLinkLine />
                  &nbsp;FreeStyle Interface
                </Link>
              )}
            </h6>
          </div>
          <div className="mx-auto my-auto text-bars align-items-center border rounded">
            <h6 className="tutorial-text text-center font-weight-bolder">
              {randomOrder ? (
                <a
                  href={`https://docs.google.com/forms/d/e/1FAIpQLScN-w1k6pS3pdgEKVoYcWLhbwikAg2vbPqBDD7A4umTStoQuA/viewform?usp=pp_url&entry.221372424=${participantId}`}
                  className="ydx-link"
                >
                  {' '}
                  <RiSurveyFill />
                  &nbsp;User Survey for Video 2
                </a>
              ) : (
                <a
                  href={`https://docs.google.com/forms/d/e/1FAIpQLSfKgSpEspPFszXUrgwiTxWK6Qk9J9dF8EBWnqUBn8-zVu--0A/viewform?usp=pp_url&entry.851854037=${participantId}`}
                  className="ydx-link"
                >
                  {' '}
                  <RiSurveyFill />
                  &nbsp;User Survey for Video 2
                </a>
              )}
            </h6>
          </div>
          <div className="mx-auto my-auto text-bars align-items-center border rounded">
            <h6 className="tutorial-text text-center font-weight-bolder">
              {randomOrder ? (
                <a
                  href={`https://sfsu.co1.qualtrics.com/jfe/form/SV_eXOXVIPvO95SlGS?participantID=${participantId}`}
                  className="ydx-link"
                >
                  {' '}
                  <RiSurveyFill />
                  &nbsp;NASA TLX Survey for Video 2
                </a>
              ) : (
                <a
                  href={`https://sfsu.co1.qualtrics.com/jfe/form/SV_cTR8f4CwWTomvdA?participantID=${participantId}`}
                  className="ydx-link"
                >
                  {' '}
                  <RiSurveyFill />
                  &nbsp;NASA TLX Survey for Video 2
                </a>
              )}
            </h6>
          </div>
        </div>
        <hr className="ydx-hr" />
        <div>
          <div className="mx-auto my-auto text-bars align-items-center border rounded">
            <h6 className="tutorial-text text-center font-weight-bolder">
              <a
                href={`https://docs.google.com/forms/d/e/1FAIpQLSfoIOrNWzZXK4tJ4QDIFLrM7-mSFhGtyW6opra67smrz2nbqw/viewform?usp=pp_url&entry.186159302=${participantId}`}
                className="ydx-link"
              >
                {' '}
                <RiSurveyFill />
                &nbsp; General Survey
              </a>
            </h6>
          </div>
        </div>
        <hr className="ydx-hr" />
      </div>
    </div>
  )
}

export default UserStudyHome
