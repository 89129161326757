import '@/assets/css/insertPublish.css'
import '@/assets/css/audioDesc.css'
import VideoPlayerControls from '@/shared/components/VideoPlayerControls/VideoPlayerControls'

interface Props {
  setHandleClicksFromParent: (value: string) => void
  handlePlayPause: () => void
  isGloballyPaused: boolean
  descriptionVolume: number
  setDescriptionVolume: (value: number) => void
  youTubeVolume: number
  setYouTubeVolume: (value: number) => void
  isPreviewAudioDescription?: boolean
}

const Buttons = ({
  setHandleClicksFromParent,
  handlePlayPause,
  isGloballyPaused,
  descriptionVolume,
  setDescriptionVolume,
  youTubeVolume,
  setYouTubeVolume,
  isPreviewAudioDescription = false,
}: Props) => {
  return (
    <div className="d-flex justify-content-evenly flex-column text-center p-4">
      <div className="row justify-content-center gx-3 gy-4">
        <div className="col-6">
          {isPreviewAudioDescription ? (
            <button
              type="button"
              className="btn btn-sm inline-bg text-dark ydx-button w-100"
              onClick={() => setHandleClicksFromParent('inline')}
            >
              <i className="fa fa-plus" /> {'   '}
              Insert Inline
            </button>
          ) : null}
        </div>
        <div className="col-6">
          {isPreviewAudioDescription ? (
            <button
              type="button"
              className="btn btn-sm extended-bg text-white ydx-button w-100"
              onClick={() => setHandleClicksFromParent('extended')}
            >
              <i className="fa fa-plus" /> {'   '}
              Insert Extended
            </button>
          ) : null}
        </div>
        <div className="col-6">
          <button
            type="button"
            className="btn btn-sm play-pause-bg text-white ydx-button"
            onClick={() => handlePlayPause()}
          >
            <i className="fa fa-play"></i> <i className="fa fa-pause"></i>{' '}
            <span className="ydx-button-lable">Play / Pause</span>
          </button>
        </div>
        <div className="col-12 text-white">
          <VideoPlayerControls
            descriptionVolume={descriptionVolume}
            setDescriptionVolume={setDescriptionVolume}
            youTubeVideoVolume={youTubeVolume}
            setYouTubeVideoVolume={setYouTubeVolume}
          />
        </div>
      </div>
    </div>
  )
}

export default Buttons
